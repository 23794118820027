export default {
  yearlyChartOptions: {
    chart: {
      height: 350,
      zoom: {
        enabled: false
      },
      type: 'area',
      toolbar: {
          show: false,
      },
      sparkline: {
          enabled: true
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
        curve: 'smooth',
        width: 2.5
    },
    colors:['#000000'],
    fill: {
      colors: ['#D8D8D8']
    },
    xaxis: {
        type: "category",
        categories: []
    },
    yaxis: {
      opposite: true
    },
    noData: {
      text: "Loading..."
    }
  },
  quarterlyChartOptions: {
    chart: {
      height: 350,
      zoom: {
        enabled: false
      },
      type: 'area',
      toolbar: {
          show: false,
      },
      sparkline: {
          enabled: true
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
        curve: 'smooth',
        width: 2.5
    },
    colors:['#000000'],
    fill: {
      colors: ['#D8D8D8']
    },
    xaxis: {
        type: "category",
        categories: []
    },
    yaxis: {
      opposite: true
    },
    noData: {
      text: "Loading..."
    }
  },
  monthlyChartOptions: {
    chart: {
      height: 350,
      zoom: {
        enabled: false
      },
      type: 'area',
      toolbar: {
          show: false,
      },
      sparkline: {
          enabled: true
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
        curve: 'smooth',
        width: 2.5
    },
    colors:['#000000'],
    fill: {
      colors: ['#D8D8D8']
    },
    xaxis: {
        type: "category",
        categories: []
    },
    yaxis: {
      opposite: true
    },
    noData: {
      text: "Loading..."
    }
  },
}
