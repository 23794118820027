<template>
  <b-container fluid class="user-content">
    <div class="stats">
      <b-row class="row-wrapper">
        <b-col cols="6">
          <div class="m-map-placeholder">
            <img :src="map" class="img-fluid" />
          </div>
          <p @click="openPlan" class="font-weight-bold fs-14 text-right cursor-pointer pright-20">{{ selectPlan }} <img :src="down_arrow" width="20px" /></p>
        </b-col>
        <b-col cols="6">
          <div class="m-map-placeholder text-right">
            <img :src="map" class="img-fluid" />
          </div>
            <p @click="openCity" class="font-weight-bold fs-14 text-right cursor-pointer">{{ selectCity }} <img :src="down_arrow" width="20px" /></p>
        </b-col>
      </b-row>

      <div class="row stat-switch mt-4">
        <div @click="switchPane" id="first" class="switch col-4" :class="{'active': first}">
          سنوية
        </div>
        <div @click="switchPane" id="second" :class="{'active': second}" class="switch col-4">
        ربع سنوية
        </div>
        <div @click="switchPane" id="third" :class="{'active': third}" class="switch col-4">
        شهرية
        </div>
      </div>

      <div v-show="first">

      <div class="row stats-switch__wrapper mt-4" @click="openYear">
        <div class="col-2 arrow-cover">
          <b-icon icon="chevron-left"></b-icon>
        </div>
        <div class="col-8 font-sfns text-center">
          {{ hijriYear }}
        </div>
        <div class="col-2 arrow-cover">
          <b-icon icon="chevron-right"></b-icon>
        </div>
      </div>

      <div class="charts-cover mt-4" v-show="year != ''">
        <b-row class="charts-info-row">
          <b-col md="5">
            <b-badge pill class="pd-10 m-width-100" variant="primary">{{ $t('residential') }}</b-badge>
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            متوسط سعر المتر
          </b-col>
        </b-row>
        <b-row class="charts-info-row font-sfns fs-16">
          <b-col dir="rtl">
            <strong>{{ residentialAvgPrice }}</strong>  ر.س
          </b-col>
        </b-row>
        <vue-apex-charts v-if="year != ''" width="100%" type="area" :options="yearlyChartsOptions" :series="yearlySeries"></vue-apex-charts>

      </div>

      <div class="charts-cover mt-4" v-show="year != ''">
        <b-row class="charts-info-row">
          <b-col md="5">
            <b-badge pill class="pd-10 m-width-100 commercial-color">{{ $t('commercial') }}</b-badge>
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            متوسط سعر المتر
          </b-col>
        </b-row>
        <b-row class="charts-info-row font-sfns fs-16">
          <b-col dir="rtl">
            <strong>{{ commercialAvgPrice }}</strong>  ر.س
          </b-col>
        </b-row>
        <vue-apex-charts v-if="year != ''" width="100%" type="area" :options="yearlyChartsOptions" :series="commercialYearlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="year != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ totalTransactions }}</strong> 
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            مجموع الصفقات
          </b-col>
        </b-row>
        <vue-apex-charts v-if="year != ''" width="100%" type="area" :options="yearlyChartsOptions" :series="totalTransactionYearlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="year != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ transactionsVolume }}</strong> ر.س
          </b-col>
          <b-col md="7" class="fs-14 font-weight-bold">
            مجموع قيمة الصفقات
          </b-col>
        </b-row>
        <vue-apex-charts v-if="year != ''" width="100%" type="area" :options="yearlyChartsOptions" :series="transactionsVolumeYearlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4 mbottom-200" v-show="year != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ latestTransaction }}</strong> ر.س
          </b-col>
          <b-col md="7" class="fs-14 font-weight-bold">
            قيمة آخر صفقة
          </b-col>
        </b-row>
        <vue-apex-charts v-if="year != ''" width="100%" type="area" :options="yearlyChartsOptions" :series="latestTransactionYearlySeries"></vue-apex-charts>
      </div>

      <div v-if="no_stat" class="text-center mtop-50">
         <img :src="stat_border" />
         <p class="fs-16 font-weight-bold">لايوجد</p>
      </div>

      </div>

      <div v-show="second">

      <div class="row stats-switch__wrapper mt-4" @click="openQuarter">
        <div class="col-2 arrow-cover">
          <b-icon icon="chevron-left"></b-icon>
        </div>
        <div class="col-8 font-sfns text-center">
          {{ quarter_text }} - {{ hijriYear }} 
        </div>
        <div class="col-2 arrow-cover">
          <b-icon icon="chevron-right"></b-icon>
        </div>
      </div>

      <div class="charts-cover mt-4" v-show="quarter != ''">
        <b-row class="charts-info-row">
          <b-col md="5">
            <b-badge pill class="pd-10 m-width-100" variant="primary">{{ $t('residential') }}</b-badge>
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            متوسط سعر المتر
          </b-col>
        </b-row>

        <b-row class="charts-info-row font-sfns fs-16">
          <b-col dir="rtl">
            <strong>{{ quarterResAvgPrice }}</strong>  ر.س
          </b-col>
        </b-row>
        <vue-apex-charts v-if="quarterExists" width="100%" type="area" :options="quarterlyChartsOptions" :series="quarterlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="quarter != ''">
        <b-row class="charts-info-row">
          <b-col md="5">
            <b-badge pill class="pd-10 m-width-100 commercial-color">{{ $t('commercial') }}</b-badge>
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            متوسط سعر المتر
          </b-col>
        </b-row>

        <b-row class="charts-info-row font-sfns fs-16">
          <b-col dir="rtl">
            <strong>{{ quarterComAvgPrice }}</strong>  ر.س
          </b-col>
        </b-row>
        <vue-apex-charts v-if="quarter != ''" width="320" type="area" :options="quarterlyChartsOptions" :series="commercialQuarterlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="quarter != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ quarterTotalTransactions }}</strong> 
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            مجموع الصفقات
          </b-col>
        </b-row>
        <vue-apex-charts v-if="quarter != ''" width="450" type="area" :options="quarterlyChartsOptions" :series="totalTransactionQuarterlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="quarter != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ quarterTransactionsVolume }}</strong> ر.س
          </b-col>
          <b-col md="7" class="fs-14 font-weight-bold">
            مجموع قيمة الصفقات
          </b-col>
        </b-row>
        <vue-apex-charts v-if="quarter != ''" width="320" type="area" :options="quarterlyChartsOptions" :series="transactionsVolumeQuarterlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4 mbottom-200">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ quarterLatestTransaction }}</strong> ر.س
          </b-col>
          <b-col md="7" class="fs-14 font-weight-bold">
            قيمة آخر صفقة
          </b-col>
        </b-row>
        <vue-apex-charts v-if="quarter != ''" width="100%" type="area" :options="quarterlyChartsOptions" :series="latestTransactionQuarterlySeries"></vue-apex-charts>
      </div>


      <div v-if="no_stat" class="text-center mtop-50">
         <img :src="stat_border" />
         <p class="fs-16 font-weight-bold">لايوجد</p>
      </div>

      </div>

      <div v-show="third">

      <div class="row stats-switch__wrapper mt-4" @click="openMonth">
        <div class="col-2 arrow-cover">
          <b-icon icon="chevron-left"></b-icon>
        </div>
        <div class="col-8 font-sfns text-center">
          {{ mon[month] }} - {{ hijriYear }}
        </div>
        <div class="col-2 arrow-cover">
          <b-icon icon="chevron-right"></b-icon>
        </div>
      </div>

      <div class="charts-cover mt-4" v-show="month != ''">
        <b-row class="charts-info-row">
          <b-col md="5">
            <b-badge pill class="pd-10 m-width-100" variant="primary">{{ $t('residential') }}</b-badge>
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            متوسط سعر المتر
          </b-col>
        </b-row>

        <b-row class="charts-info-row font-sfns fs-16">
          <b-col dir="rtl">
            <strong>{{ monthResAvgPrice }}</strong>  ر.س
          </b-col>
        </b-row>
        <vue-apex-charts v-if="month != ''" width="100%" type="area" :options="monthlyChartsOptions" :series="monthlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="month != ''">
        <b-row class="charts-info-row">
          <b-col md="5">
            <b-badge pill class="pd-10 m-width-100 commercial-color">{{ $t('commercial') }}</b-badge>
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            متوسط سعر المتر
          </b-col>
        </b-row>
        <b-row class="charts-info-row font-sfns fs-16">
          <b-col dir="rtl">
            <strong>{{ monthComAvgPrice }}</strong>  ر.س
          </b-col>
        </b-row>
        <vue-apex-charts v-if="month != ''" width="100%" type="area" :options="monthlyChartsOptions" :series="monthlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="month != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ monthTotalTransactions }}</strong> 
          </b-col>
          <b-col md="7" class="fs-16 font-weight-bold">
            مجموع الصفقات
          </b-col>
        </b-row>
        <vue-apex-charts v-if="month != ''" width="100%" type="area" :options="monthlyChartsOptions" :series="totalTransactionMonthlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4" v-show="month != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ monthTransactionsVolume }}</strong> ر.س
          </b-col>
          <b-col md="7" class="fs-14 font-weight-bold">
            مجموع قيمة الصفقات
          </b-col>
        </b-row>
        <vue-apex-charts v-if="month != ''" width="100%" type="area" :options="monthlyChartsOptions" :series="transactionsVolumeMonthlySeries"></vue-apex-charts>
      </div>

      <div class="charts-cover mt-4 mbottom-200" v-show="month != ''">
        <b-row class="charts-info-row">
          <b-col dir="rtl" md="5" class="font-sfns fs-14">
            <strong>{{ monthLatestTransaction }}</strong> ر.س
          </b-col>
          <b-col md="7" class="fs-14 font-weight-bold">
            قيمة آخر صفقة
          </b-col>
        </b-row>
        <vue-apex-charts v-if="month != ''" width="100%" type="area" :options="monthlyChartsOptions" :series="latestTransactionMonthlySeries"></vue-apex-charts>
      </div>


      <div v-if="no_stat" class="text-center mtop-50">
         <img :src="stat_border" />
         <p class="fs-16 font-weight-bold">لايوجد</p>
      </div>

      </div>

      <b-modal id="city" modal-class="z-index-100001" title="" :hide-footer=true content-class="signup-content z-index-100001" header-class="signup-header">
         <h1 class=" mb-4 text-right">اختر المدينة</h1>
         <div>
           <b-form-select v-model="city" :options="cities"></b-form-select>
         </div>
      </b-modal>

      <b-modal id="plan" modal-class="z-index-100001" title="" :hide-footer=true content-class="signup-content z-index-100001" header-class="signup-header">
         <h1 class=" mb-4 text-right">اختر المخطط</h1>
         <div>
           <b-form-select v-model="plan" :options="plans"></b-form-select>
         </div>
      </b-modal>

      <b-modal id="year" modal-class="z-index-100001" title="" :hide-footer=true content-class="signup-content z-index-100001" header-class="signup-header">
         <h1 class=" mb-4 text-right">اختر السنة</h1>
         <div>
           <b-form-select class="font-sfns" v-model="year" :options="years"></b-form-select>
         </div>
      </b-modal>

      <b-modal id="quarter" modal-class="z-index-100001" title="" :hide-footer=true content-class="signup-content z-index-100001" header-class="signup-header">
         <h1 class=" mb-4 text-right">اختر ربع السنة</h1>
         <div>
           <b-form-select class="font-sfns" v-model="quarter" :options="quarters"></b-form-select>
         </div>
      </b-modal>

      <b-modal id="month" modal-class="z-index-100001" title="" :hide-footer=true content-class="signup-content z-index-100001" header-class="signup-header">
         <h1 class=" mb-4 text-right">اختر الشهر</h1>
         <div>
           <b-form-select class="font-sfns" v-model="month" :options="months"></b-form-select>
         </div>
      </b-modal>

    </div>
  </b-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import chartsOptions from '@/data/chartsOptions.js'
import utils from "@/helper/utils.js"
import {quarter as quart, month as mon} from "@/data/chartsData"
//import Chart from "@/components/Chart.vue"

export default {
  title: "Statistics",
  data() {
    return {
      first: true,
      second: false,
      third: false,
      map: require("@/assets/images/icons/map_box.png"),
      down_arrow: require("@/assets/images/icons/dropdown.svg"),
      stat_border: require("@/assets/images/icons/stat_border.svg"),
      city: "",
      plan: "",
      year: "",
      quarter: "",
      quarter_text: "",
      q_values: ["1", "2", "3", "4"],
      month: "",
      yearlyChartsOptions: chartsOptions.yearlyChartOptions,
      quarterlyChartsOptions: chartsOptions.quarterlyChartOptions,
      monthlyChartsOptions: chartsOptions.monthlyChartOptions,
      no_stat: false,
      quart: quart,
      mon: mon
    }
  },
  components: {
    VueApexCharts,
    //Chart
  },
  watch: {
    '$store.state.plan.charts_categories'(newVal) {
      this.yearlyChartsOptions = { ...this.yearlyChartOptions, ...{
        xaxis: {
          type: "category",
          categories: newVal
        }
     }}
    },
    '$store.state.plan.quarterly_categories'(newVal) {
      this.quarterlyChartsOptions = { ...this.quarterlyChartOptions, ...{
        xaxis: {
          type: "category",
          categories: newVal
        }
     }}
    },
    '$store.state.plan.monthly_categories'(newVal) {
      this.monthlyChartsOptions = { ...this.monthlyChartOptions, ...{
        xaxis: {
          type: "category",
          categories: newVal
        }
     }}
    },
    '$store.state.plan.yearly'(obj) {
        if(!obj.length) {
          this.year = ""
        }
    },
    city(newVal) {
      if (newVal != "") {
        this.fetchPlans()
      }
    },
    plan(newVal) {
      if (newVal != "") {
        this.fetchYearlyStatistics()
      }
    },
   second() {
     if (this.second) {
       if (this.year == "")
         return
       this.fetchQuarterlyStatistics()
     }
   },
   third() {
     if (this.third) {
       if (this.year == "")
         return
       this.fetchMonthlyStatistics()
     }
   },
   quarter() {
      let qIndex = this.quarters.findIndex(q => q.value == this.quarter)
      this.quarter_text = this.quarters[qIndex]["text"]
   }
  },
  computed: {
    cities() {
      let cities = this.$store.getters['city/listCities']
      return cities
    },
    selectCity() {
      if (this.city == "")
        return "اختر المدينة"
      const findIndex = this.cities.findIndex((city) => city.value === this.city)
      return this.cities[findIndex]["text"]
    },
    selectPlan() {
      if (this.plan == "")
        return "اختر المخطط"
      const findIndex = this.plans.findIndex((plan) => plan.value === this.plan)
      if(findIndex != -1) {
        return this.plans[findIndex]["text"]
      }
      return "اختر المخطط"
    },
    plans() {
      let plans = this.$store.getters['plan/listPlansWithDistrict']
      return plans
    },
    years() {
      return this.$store.getters['plan/listYears']
    },
    quarters() {
      return this.$store.getters['plan/listQuarters']
    },
    months() {
      return this.$store.getters['plan/listMonths']
    },
    yearlySeries() {
      return this.$store.getters['plan/yearSeries']
      //return this.$store.state.plan.yearlySeries
    },
    quarterlySeries() {
      return this.$store.getters['plan/quarterSeries']
    },
    monthlySeries() {
      return this.$store.getters['plan/monthSeries']
    },
    commercialYearlySeries() {
      return this.$store.getters['plan/commercialYearSeries']
    },
    commercialQuarterlySeries() {
      return this.$store.getters['plan/commercialQuarterSeries']
    },
    totalTransactionYearlySeries() {
      return this.$store.getters['plan/totalTransactionYearSeries']
    },
    totalTransactionQuarterlySeries() {
      return this.$store.getters['plan/totalTransactionQuarterSeries']
    },
    totalTransactionMonthlySeries() {
      return this.$store.getters['plan/totalTransactionMonthSeries']
    },
    transactionsVolumeYearlySeries() {
      return this.$store.getters['plan/transactionsVolumeYearSeries']
    },
    transactionsVolumeQuarterlySeries() {
      return this.$store.getters['plan/transactionsVolumeQuarterSeries']
    },
    transactionsVolumeMonthlySeries() {
      return this.$store.getters['plan/transactionsVolumeMonthSeries']
    },
    latestTransactionYearlySeries() {
      return this.$store.getters['plan/latestTransactionYearSeries']
    },
    latestTransactionQuarterlySeries() {
      return this.$store.getters['plan/latestTransactionQuarterSeries']
    },
    latestTransactionMonthlySeries() {
      return this.$store.getters['plan/latestTransactionMonthSeries']
    },
    yearly() {
      return this.$store.state.plan.yearly
    },
    quarterly() {
      return this.$store.state.plan.quarterly
    },
    monthly() {
      return this.$store.state.plan.monthly
    },
    residentialAvgPrice() {
      if(this.year != "")
        return this.formatNumber(this.yearly[this.year]["residential_avg_price"])
      return ""
    },
    latestTransaction() {
      if(this.year != "")
        return this.formatNumber(this.yearly[this.year]["latest_transaction"])
      return ""
    },
    quarterResAvgPrice() {
      if(this.q_values.indexOf(this.quarter) !== -1) {
        let quart = this.formatNumber(this.quarterly[this.quarter]["residential_avg_price"])
        console.log(quart)
        if (quart == 0 || quart == "") return "لايوجد"
        return quart
      }
      return "لايوجد"
    },
    monthResAvgPrice() {
      if(this.month != "")
        return this.formatNumber(this.monthly[this.month]["residential_avg_price"])
      return ""
    },
    commercialAvgPrice() {
      if(this.year != "") {
        let com = this.formatNumber(this.yearly[this.year]["commercial_avg_price"])
        if (com == 0) return "لايوجد"
        return com
      }
      return ""
    },
    monthComAvgPrice() {
      if(this.month != "") {
        let mon = this.formatNumber(this.monthly[this.month]["commercial_avg_price"])
        if (mon == 0) return "لايوجد"
        return mon
      }
      return ""
    },
    quarterComAvgPrice() {
      if(this.q_values.indexOf(this.quarter) !== -1) {
        let quart = this.formatNumber(this.quarterly[this.quarter]["commercial_avg_price"])
        if (quart == 0 || quart == "") return "لايوجد"
        return quart
      }
      return "لايوجد"
    },
    totalTransactions() {
      if(this.year != "")
        return this.yearly[this.year]["total_transactions"]
      return ""
    },
    quarterTotalTransactions() {
      if(this.q_values.indexOf(this.quarter) !== -1) {
        let quart = this.quarterly[this.quarter]["total_transactions"]
        if(quart == 0 || quart == "") return "لايوجد"
      }
      return "لايوجد"
    },
    transactionsVolume() {
      if(this.year != "")
        return this.formatNumber(this.yearly[this.year]["volume_of_transaction"])
      return ""
    },
    quarterTransactionsVolume() {
      if(this.q_values.indexOf(this.quarter) !== -1) {
        let quart = this.formatNumber(this.quarterly[this.quarter]["volume_of_transaction"])
        if(quart == 0 || quart == "") return "لايوجد"
      }
      return "لايوجد"
    },
    quarterLatestTransaction() {
      if(this.q_values.indexOf(this.quarter) !== -1) {
        let quart = this.formatNumber(this.quarterly[this.quarter]["latest_transaction"])
        if(quart == 0 || quart == "") return "لايوجد"
      }
      return ""
    },
    monthLatestTransaction() {
      if(this.month != "")
        return this.formatNumber(this.monthly[this.month]["latest_transaction"])
      return ""
    },
    monthTransactionsVolume() {
      if(this.month != "")
        return this.formatNumber(this.monthly[this.month]["volume_of_transaction"])
      return ""
    },
     monthTotalTransactions() {
      if(this.month != "")
        return this.monthly[this.month]["total_transactions"]
      return ""
    },
    hijriYear() {
      return utils.hijriYear(this.year)
    },
    quarterExists() {
      if(this.q_values.indexOf(this.quarter) !== -1)
        return true
      return false
    }
  },
  methods: {
    fetchCities() {
      const payload = {
        meta: {
          page: 1,
          paginate: 200
        }
      }
      this.$store.dispatch("city/fetchCities", payload)
      .then(() => {
        //this.city = this.cities[0]["value"]
        //this.fetchPlans()
      })
    },
    fetchPlans() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          page: 1,
          paginate: 1000,
          city_id: this.city
        }
      }
      this.$store.dispatch("plan/fetchPlans", payload)
      .then(() => {
        this.$bvModal.hide('city')
        loader.hide()
      })
    },
    fetchYearlyStatistics() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          stat_type: "plan",
          plotno_or_city: this.city,
          stat_type_value: this.plan
        }
      }
      this.$store.dispatch("plan/fetchYearlyStatistics", payload)
      .then((res) => {
        this.$bvModal.hide('plan')
        if(Object.keys(res.data).length > 0) {
          this.year = this.years[0]["value"]
          this.no_stat = false
        }
        else {
          this.year = ""
          this.no_stat = true
        }
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    fetchQuarterlyStatistics() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          stat_type: "plan",
          year: this.year,
          stat_type_value: this.plan
        }
      }
      this.$store.dispatch("plan/fetchQuarterlyStatistics", payload)
      .then(() => {
        this.quarter = this.quarters[0]["value"]
        this.quarter_text = this.quarters[0]["text"]
        loader.hide()
      })
    },
    fetchMonthlyStatistics() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          stat_type: "plan",
          year: this.year,
          stat_type_value: this.plan
        }
      }
      this.$store.dispatch("plan/fetchMonthlyStatistics", payload)
      .then((res) => {
        if(Object.keys(res.data).length > 0) {
          this.month = this.months[0]["value"]
        }
        else {
          this.month = ""
        }
        loader.hide()
      })
    },
    openCity() {
      this.$bvModal.show('city')
    },
    openPlan() {
      if(this.city == "")
        return

      this.$bvModal.show('plan')
    },
    openYear() {
      this.$bvModal.show('year')
    },
    openQuarter() {
      this.$bvModal.show('quarter')
    },
    openMonth() {
      this.$bvModal.show('month')
    },
    formatNumber(val) {
      return utils.numberFormat(val)
    },
    switchPane(event) {
      if (event.target.classList.length > 2)
        return

      if(event.target.id == "first") {
        this.first = !this.first
        this.second = false
        this.third = false
      }

      if(event.target.id == "second") {
        this.second = !this.second
        this.first = false
        this.third = false
      }

      if(event.target.id == "third") {
        this.third = !this.third
        this.first = false
        this.second = false
      }
    }
  },
  created() {
    this.fetchCities()
  },
  mounted() {

  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
